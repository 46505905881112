import React from 'react'
import {
  Wrapper,
  Container,
  Title,
  Text,
  Eye,
  Button,
  FiexBottomWrapper,
  LineLeft,
  LineRight
} from './styled'

import EyeSvg from '../../../images/eye-opened-main-page.svg'

const PageNotFound = () => {
  return (
    <Wrapper>
      <Container>
        <Title>Page not found</Title>
        <Eye src={EyeSvg} />
        <Text>We cant seem to find the page you were looking for.</Text>
        <LineLeft />
        <LineRight />
      </Container>
      <FiexBottomWrapper>
        <Button to='/'>Go to home page</Button>
      </FiexBottomWrapper>
    </Wrapper>
  )
}

export default PageNotFound
