import React from 'react'
import MainLayout from '../components/LayoutsRelated/Layouts/MainLayout'
import PageNotFound from '../components/PageRelated/404/PageNotFound'

const NotFoundPage = () => (
  <MainLayout isPage>
    <PageNotFound />
  </MainLayout>
)

export default NotFoundPage
